@import './app/styles/styles.scss';

html, body { height: 100%; font-size: 1px; overflow-y: hidden;}
body {
  --primary-color: white;
  --primary-background: black;
  --bg-transition: background-color .1s linear;

  margin: 0; font-family: $primary-font;
  color: var(--primary-color); background: var(--primary-background);
  overflow-x: hidden;
  width: 100%;
  position: fixed; top: 0; left: 0;
  transition: var(--bg-transition);
}

a{
  text-decoration: none; color: inherit; outline: none;
  -webkit-tap-highlight-color: transparent;
}

button{
  cursor: pointer;
}

.mock-button{
  cursor: pointer; transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.mock-button:hover{
  background: rgba(255, 255, 255, 0.1);
}

* {
  user-select: none;
}

img, video {
  pointer-events: none;
}

/*moble only*/
@media #{$break1} {
  html, body{
    font-size: 0.6px;
  }
}

/*tablet only*/
@media #{$break2}{
    
}

/*desktop only*/
@media #{$break3}{

}